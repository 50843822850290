import { Field, ImageField, RichTextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

export enum componentVariationValue {
  OneColumn = 'One column',
  TwoColumn = 'Two column',
}

export enum iconImagePosition {
  ImageLeft = 'ImageLeft',
  ImageUp = 'ImageUp',
}

interface IconWithDescriptionList {
  fields: {
    bulletIcon: ImageField;
    description: RichTextField;
  };
}

export type IFlexiContainerProps = ComponentProps & {
  fields: {
    description: RichTextField;
    heading: Field<string>;
    title: Field<string>;
    titleIcon: ImageField;
    iconWithTitleVariant: { value: string };
    bulletDescriptionList: IconWithDescriptionList[];
    componentVariations: Field<string>;
  };
};
