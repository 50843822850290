/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ComponentProps } from 'lib/component-props';
import { TextField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { UIButton } from '../shared/UIButton.styles';
import { updateParticipantType } from 'lib/utils/axiosCDPFetcher';
import { getOrCreateEnrollmentId } from 'lib/utils/enrollmentUtil';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getQuery } from '../../lib/utils/common';
import { trackEvent } from '../../lib/utils/gtmService';

interface IAnswerOptionItem {
  fields: {
    label: TextField;
    link: LinkField;
    participantType: TextField;
  };
}
interface IContactInfoQuestionnaire extends ComponentProps {
  fields?: {
    heading: TextField;
    question: TextField;
    answerOptions: IAnswerOptionItem[];
    nextButtonText: TextField;
    ariaLabel: TextField;
  };
}

const fallback = { value: '' };
const ContactInfoQuestionnaire: React.FC<IContactInfoQuestionnaire> = (props) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [participantType, setParticipantType] = useState<string>('');
  const router = useRouter();
  const heading: TextField = props.fields?.heading || fallback;
  const question: TextField = props.fields?.question || fallback;
  const nextButtonText: TextField = props.fields?.nextButtonText || fallback;
  const answerOptions = props.fields?.answerOptions || [];
  const { query } = router;

  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const selectedAnswer = answerOptions.find(
      (i) => i.fields?.link?.value?.href === selectedOption
    );
    if (selectedAnswer) {
      setParticipantType(String(selectedAnswer?.fields?.participantType?.value) || '');
      trackEvent({
        event: 'Participant Type Selected',
        participantType: String(selectedAnswer?.fields?.participantType?.value) || '',
      });
    }
  }, [selectedOption]);

  const handleSubmit = async () => {
    const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
    await updateParticipantType(
      potentialParticipant_ID,
      getOrCreateEnrollmentId(),
      participantType
    );
    router.push(
      {
        pathname: selectedOption,
        query: getQuery(query),
      },
      selectedOption
    );
  };

  if (!props?.fields) return <></>;

  return (
    // @ts-ignore
    <Box id="contact-info-questionare">
      <Paper sx={{ borderRadius: '0.5rem', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)' }}>
        <Box display="flex" flexDirection="column" padding="2rem">
          <Typography
            variant="h4"
            fontSize="20px"
            fontWeight="600"
            sx={{ color: (theme) => theme.palette.themeColor.main }}
          >
            <Text field={heading}></Text>
          </Typography>
          <Box marginTop="1.4rem">
            <FormControl>
              <div id="ContactInfoQuestion">
                <Typography
                  sx={{
                    color: (theme) => theme.palette.neutral8.main,
                    fontSize: '1rem',
                    fontWeight: '600',
                    lineHeight: '24px',
                  }}
                >
                  <Text field={question}></Text>
                </Typography>
              </div>
              <RadioGroup
                aria-labelledby={
                  (props?.fields?.ariaLabel?.value as string) || 'ContactInfoQuestion'
                }
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{
                  marginTop: '0.5rem',
                }}
              >
                {answerOptions.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.fields?.link?.value?.href || ''}
                    control={<Radio />}
                    label={
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.neutral8.main,
                          fontSize: '1rem',
                          fontWeight:
                            option.fields?.link?.value?.href === selectedOption ? '600' : '400',
                          lineHeight: '24px',
                        }}
                      >
                        <Text field={option.fields?.label || fallback}></Text>
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box marginTop="3rem">
            <UIButton
              className="btn"
              disabled={!selectedOption}
              variant="contained"
              onClick={handleSubmit}
              sx={{
                minWidth: 'fit-content',
                padding: '8px 24px',
              }}
            >
              <Typography>
                <Text field={nextButtonText}></Text>
              </Typography>
            </UIButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContactInfoQuestionnaire;
