import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { trackEvent } from '../utils/gtmService';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { CR_SITE } from '../../lib/constant';

const useUpdateGTMForOutcomePages = (route: any): any => {
  const { sitecoreContext } = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name;
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);

  // List of urls for outcome pages
  const listOfOutcomePages = {
    '/dnq/adult': 'Landed on DNQ adult page',
    '/dnq/minor': 'Landed on DNQ minor page',
    '/dnq/caregiver': 'Landed on DNQ caregiver page',
    '/minor-dnq/adult': 'Landed on MinorDNQ adult page',
    '/minor-dnq/minor': 'Landed on MinorDNQ minor page',
    '/minor-dnq/caregiver': 'Landed on MinorDNQ caregiver page',
    '/minor-dnq/guardian': 'Landed on MinorDNQ guardian page',
    '/minor-dnq/not_in_range': 'Landed on MinorDNQ not in range page',
    '/no-sites-available/adult': 'Landed on NSA adult page',
    '/no-sites-available/minor': 'Landed on NSA minor page',
    '/no-sites-available/caregiver': 'Landed on NSA caregiver page',
    '/no-sites-available-max-capacity/adult': 'Landed on NSA-MC adult page',
    '/no-sites-available-max-capacity/minor': 'Landed on NSA-MC minor page',
    '/no-sites-available-max-capacity/caregiver': 'Landed on NSA-MC caregiver page',
    '/no-sites-convenient/adult': 'Landed on NSC adult page',
    '/no-sites-convenient/minor': 'Landed on NSC minor page',
    '/no-sites-convenient/caregiver': 'Landed on NSC caregiver page',
  };

  useEffect(() => {
    const isOutcomePage = route?.fields?.isOutcomePage?.value;
    if (isOutcomePage === true) {
      Object.entries(listOfOutcomePages).forEach((k) => {
        if (router.asPath.includes(k[0])) {
          trackEvent(
            {
              event: k[1],
            },
            siteName === CR_SITE
          );
        }
      });
    }
    // if (isOutcomePage === true && !router.asPath.includes('siteselectionsuccess')) {
    // }
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query]);

  return isLoaded;
};

export default useUpdateGTMForOutcomePages;
