// Third party imports
import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Text, Field, ImageField, RichText, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import lowerCase from 'lodash/lowerCase';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import getCallCenterNumber from '../../../store/selectors/getCallCenterNumber';
import pnMergetagReplacer from '../../../lib/utils/pnMergeTagReplacer';

// Project specific imports
import { ComponentProps } from 'lib/component-props';
import { ButtonType, IButton } from '../../../lib/type/Button';
import { HeroBannerType } from './DTPHeroBanner.type';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { JOURNEYS } from '../../../constants';
import {
  StBannerContainer,
  StBannerContent,
  StBannerWrapper,
  StBigLinkButton,
  StButtonStack,
  StOrText,
  StRichTextGridItem,
} from './DTPHeroBanner.styles';
import { JSSLink } from '../../shared/Links/JSSLink.styles';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import CustomNextImage from '../../shared/CustomNextImage/CustomNextImage';
import { trackEvent } from '../../../lib/utils/gtmService';

type DTPHeroBannerProps = ComponentProps & {
  fields: {
    title: Field<string>;
    image: ImageField;
    content: Field<string>;
    ctaButtons: IButton[];
    componentVariantType: { value: string };
    orText: Field<string>;
    imageDesktop: ImageField;
    imageMobile: ImageField;
    imageTablet: ImageField;
  };
};
const fallbackLink: LinkField = { value: { href: '', text: '' } };
const DTPHeroBanner = (props: DTPHeroBannerProps): JSX.Element => {
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const callCenterNumber = getCallCenterNumber(sitecoreContext);
  const siteSettings: any = sitecoreContext?.siteSettings;
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;
  const { fields } = props || {};
  const isNotificationBannerOpen = useSelector(
    (state: RootState) => state.config.isNotificationBannerOpen
  );
  // Setting default variant as V1. Note that content should be managed
  // by Author accordingly
  const variantType = fields?.componentVariantType?.value
    ? lowerCase(fields?.componentVariantType?.value).split(' ').join('')
    : HeroBannerType.V1;
  const isVariant1 = variantType === HeroBannerType.V1;
  const ctaButtonsField = fields?.ctaButtons;
  const [buttonsArr, setButtonsArr] = useState<IButton[]>([]);

  const scrollToSection = (e: any) => {
    trackEvent({
      event: 'Hero CTA Clicked',
    });
    e.preventDefault();
    if (e) {
      const itemId = e.currentTarget.hash ? e.currentTarget.hash.split('#')[1] : undefined;
      const itemOffset = document.getElementById(itemId)?.offsetTop;
      const margin = isNotificationBannerOpen ? 50 : 0;
      if (itemOffset) {
        window.scroll({
          top: itemOffset - margin,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(ctaButtonsField)) {
      const newButtons = ctaButtonsField.map((item) => {
        const isCallCenter = item.fields.callCenterCTAButton?.value;
        // if `isCallCenter` property is present its a CallCenter CTA button
        if (typeof isCallCenter !== 'undefined' && item.fields.link) {
          const href = `tel:${callCenterNumber.value || ''}`;
          const text = pnMergetagReplacer(
            item.fields.link.value.text,
            String(callCenterNumber.value || ''),
            false
          );
          item.fields.link.value.href = href;
          item.fields.link.value.text = text;
          return item;
        }
        return item;
      });
      setButtonsArr(newButtons);
    }
  }, [callCenterNumber.value]);

  if (!props?.fields) return <></>;

  return (
    <StBannerWrapper id="dtp-hero-banner">
      {!isEditorActive() && (
        <CustomNextImage
          id="dtp-hero-banner-background-image"
          priority={true} // When true, the image will be considered high priority and preload
          style={{ zIndex: 0 }}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          mobileImg={fields?.imageMobile}
          tabletImg={fields?.imageTablet}
          desktopImg={fields?.imageDesktop}
        />
      )}
      <StBannerContainer
        id="hero-banner"
        container
        variant={variantType}
        style={isEditorActive() ? { backgroundImage: `url(${fields?.image?.value?.src})` } : {}}
      >
        <StBannerContent container item sx={{ zIndex: 1 }}>
          <Grid item>
            <Typography
              gutterBottom
              variant="h1"
              sx={{
                fontSize: '2rem',
                marginBottom: '8px',
                [theme.breakpoints.down('md')]: {
                  fontSize: '2rem',
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.875rem',
                },
              }}
            >
              <Text field={fields?.title}></Text>
            </Typography>
          </Grid>
          <StRichTextGridItem item variant={variantType}>
            <RichText field={fields?.content} />
          </StRichTextGridItem>
        </StBannerContent>
        <Grid
          item
          sx={{
            marginTop: '22px',
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
          }}
        >
          <StButtonStack spacing={2}>
            {buttonsArr.map((ctaButton: IButton, index: number) => {
              const btnType: string =
                ctaButton?.fields?.buttonVariant?.value || ButtonType.CONTAINED;
              const ctaButtonLink = isJourneyType3
                ? ctaButton?.fields?.questionnaireCtaLink
                : ctaButton?.fields?.screenerCtaLink;

              if (lowerCase(btnType) === ButtonType.CONTAINED) {
                if (!isScreenerLandingPageEnabled) {
                  return (
                    <>
                      <JSSLink
                        id={ctaButton?.name}
                        className={'hero-cta-btn btn'}
                        key={ctaButton?.id}
                        onClick={(e) => scrollToSection(e)}
                        variant="contained"
                        field={ctaButton?.fields?.link || fallbackLink}
                        aria-label={
                          ctaButton?.fields?.ariaLabel?.value ||
                          ctaButton?.fields?.link?.value?.text
                        }
                      />
                      {index === 0 &&
                        buttonsArr.length > 1 &&
                        (props?.fields?.orText?.value != null ||
                          props?.fields?.orText?.value != '' ||
                          props?.fields?.orText?.value != undefined) && (
                          <StOrText spacing={2}>
                            <Text field={props?.fields?.orText} />
                          </StOrText>
                        )}
                    </>
                  );
                }
                return (
                  <JSSLink
                    id={ctaButton?.name}
                    className={'hero-cta-btn btn'}
                    key={ctaButton?.id}
                    variant="contained"
                    field={ctaButtonLink || fallbackLink}
                  />
                );
              } else if (btnType === ButtonType.OUTLINED) {
                if (isVariant1) {
                  return (
                    <>
                      <StBigLinkButton
                        id={ctaButton?.name}
                        key={ctaButton?.id}
                        variant="outlined"
                        field={ctaButton?.fields?.link || fallbackLink}
                        aria-label={
                          ctaButton?.fields?.ariaLabel?.value ||
                          ctaButton?.fields?.link?.value?.text
                        }
                      />
                      {index === 0 &&
                        buttonsArr.length > 1 &&
                        (props?.fields?.orText?.value != null ||
                          props?.fields?.orText?.value != '' ||
                          props?.fields?.orText?.value != undefined) && (
                          <StOrText spacing={2}>
                            <Text field={props?.fields?.orText} />
                          </StOrText>
                        )}
                    </>
                  );
                }
                return (
                  <>
                    <JSSLink
                      id={ctaButton?.name}
                      className={'btn'}
                      key={ctaButton?.id}
                      variant="outlined"
                      field={ctaButton?.fields?.link || fallbackLink}
                      aria-label={
                        ctaButton?.fields?.ariaLabel?.value || ctaButton?.fields?.link?.value?.text
                      }
                    />
                    {index === 0 &&
                      buttonsArr.length > 1 &&
                      (props?.fields?.orText?.value != null ||
                        props?.fields?.orText?.value != '' ||
                        props?.fields?.orText?.value != undefined) && (
                        <StOrText spacing={2}>
                          <Text field={props?.fields?.orText} />
                        </StOrText>
                      )}
                  </>
                );
              }
              return (
                <>
                  <JSSLink
                    id={ctaButton?.name}
                    className={'btn'}
                    key={ctaButton?.id}
                    field={ctaButton?.fields?.link || fallbackLink}
                    aria-label={
                      ctaButton?.fields?.ariaLabel?.value || ctaButton?.fields?.link?.value?.text
                    }
                  />
                  {index === 0 &&
                    buttonsArr.length > 1 &&
                    (props?.fields?.orText?.value != null ||
                      props?.fields?.orText?.value != '' ||
                      props?.fields?.orText?.value != undefined) && (
                      <StOrText spacing={2}>
                        <Text field={props?.fields?.orText} />
                      </StOrText>
                    )}
                </>
              );
            })}
          </StButtonStack>
        </Grid>
      </StBannerContainer>
    </StBannerWrapper>
  );
};

export default DTPHeroBanner;
