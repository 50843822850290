import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import SiteInfoCard from '../../components/SiteInfoCard/SiteInfoCard';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { siteSelection } from '../../features/siteSelectionSlice';
import IconMarker from '../../../public/icon-marker.svg';
import { RootState } from '../../store/store';

const SitesMap = ({
  siteDetails,
  fields,
  buttonText,
  siteAddressFormat,
  mapIcon,
  phoneIcon,
  isError,
}: any) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const containerStyle = {
    width: '100%',
    height: '550px',
    maxHeight: '550px',
    borderBottomRightRadius: isDesktop ? '0.5rem' : 0,
    borderTopRightRadius: isDesktop ? '0.5rem' : 0,
  };
  const firstSiteInList = (siteDetails && siteDetails[0]) || {};

  const { sitecoreContext } = useSitecoreContext();

  const siteSettings: any = sitecoreContext?.siteSettings;
  const centerCord = useMemo(
    () => ({
      lat: firstSiteInList?.latitude || Number(siteSettings?.countryLatitude?.value) || 58.3,
      lng: firstSiteInList?.longitude || Number(siteSettings?.countryLongitude?.value) || -134.43,
    }),
    [firstSiteInList, siteSettings?.countryLatitude, siteSettings?.countryLongitude]
  );
  const [hoveredPlace, setHoveredPlace] = useState<any>(null);
  const dispatch = useDispatch();
  const selectedPlace = useSelector((state: RootState) => state.selectedSite);

  let isLoaded = false;
  const lng =
    sitecoreContext?.site?.name === 'ClinicalResearch' ? 'en' : sitecoreContext?.language || 'en';

  try {
    const loaderObj = useJsApiLoader({
      id: 'google-map-script',
      language: lng,
      googleMapsApiKey: atob(siteSettings?.googleMapsAPIKey?.value || ''),
    });

    isLoaded = loaderObj.isLoaded;
  } catch (_error) {}

  const selectedInfoWindow = (lat: number, lng: number, siteInfo: any) => {
    return (
      <InfoWindowF
        position={{ lat: lat, lng: lng }}
        onCloseClick={() => dispatch(siteSelection({} as any))}
      >
        <SiteInfoCard
          siteInfo={siteInfo}
          buttonText={buttonText}
          siteAddressFormat={siteAddressFormat}
          isShowSelectedColor={false}
          virtualIcon={fields?.virtualIcon}
          virtualMapIcon={fields?.virtualMapIcon}
          virutalSiteText={fields?.virutalSiteText}
          isCompactCard={isDesktop ? fields?.isCompactCard?.value : false}
        />
      </InfoWindowF>
    );
  };

  const hoverInfoWindow = (lat: number, lng: number, siteInfo: any) => {
    return (
      <InfoWindowF position={{ lat: lat, lng: lng }} zIndex={100}>
        <SiteInfoCard
          siteInfo={siteInfo}
          buttonText={buttonText}
          siteAddressFormat={siteAddressFormat}
          isShowSelectedColor={false}
          mapIcon={mapIcon}
          phoneIcon={phoneIcon}
          virtualIcon={fields?.virtualIcon}
          virtualMapIcon={fields?.virtualMapIcon}
          virutalSiteText={fields?.virutalSiteText}
          isCompactCard={isDesktop ? fields?.isCompactCard?.value : false}
        />
      </InfoWindowF>
    );
  };

  return (
    <Box
      id="sites-map"
      sx={{
        borderBottomRightRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        '.gm-style-iw-c': {
          padding: '0px',
          overflow: 'visible',
          maxWidth: isDesktop && fields?.isCompactCard ? '290px !important' : 'auto',
        },
        '.gm-style-iw-d': {
          overflow: 'visible !important',
        },
        '.gm-style-iw-d + button': {
          display: 'none !important',
        },
        '.gm-style-iw-chr': {
          display: 'none !important',
        },
      }}
    >
      <>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerCord}
            zoom={4}
            options={{ scrollwheel: false, mapTypeControl: false }}
            onClick={() => {
              dispatch(
                siteSelection({
                  ...selectedPlace,
                  selectionType: 'Partial',
                  shouldInfoWindowVisible: false,
                })
              );
            }}
          >
            {!isError &&
              siteDetails &&
              siteDetails.map((place: any, index: any) => {
                const markerUrl = place?.isHybridSite
                  ? fields?.virtualMapIcon?.value?.src
                  : fields?.mapIcon?.value?.src;
                return (
                  <MarkerF
                    onClick={() => {
                      if (selectedPlace?.siteInfo?.siteCode === place.siteCode) {
                        dispatch(
                          siteSelection({
                            siteInfo: place,
                            selectionType: 'Partial',
                            shouldInfoWindowVisible: !selectedPlace?.shouldInfoWindowVisible,
                          } as any)
                        );
                      } else {
                        dispatch(
                          siteSelection({
                            siteInfo: place,
                            selectionType: 'Partial',
                            shouldInfoWindowVisible: true,
                          })
                        );
                      }
                    }}
                    onMouseOver={() => {
                      setHoveredPlace(place);
                    }}
                    onMouseOut={() => {
                      setHoveredPlace(null);
                    }}
                    key={index}
                    position={{ lat: place.latitude, lng: place.longitude }}
                    icon={markerUrl ? markerUrl : <IconMarker></IconMarker>}
                    //onLoad={handleOnLoad}
                  >
                    {place.siteCode === selectedPlace?.siteInfo.siteCode &&
                    selectedPlace.shouldInfoWindowVisible
                      ? selectedInfoWindow(place.latitude, place.longitude, place)
                      : null}
                    {hoveredPlace && hoveredPlace.siteCode === place.siteCode
                      ? hoverInfoWindow(place.latitude, place.longitude, place)
                      : null}
                  </MarkerF>
                );
              })}
          </GoogleMap>
        )}
      </>
    </Box>
  );
};
export default SitesMap;
