import * as React from 'react';
import { IconWithTitle } from '../shared/IconWithTitle';
import IconWithDescriptionBlock from '../shared/IconWithDescriptionBlock/IconWithDescriptionBlock';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import {
  StTwoColumnContainer,
  StLeftColumnContainer,
  StRightColumnContainer,
  StTypographyTitle,
  StTypographyDescription,
} from './FlexiContainer.styles';
import {
  IFlexiContainerProps,
  componentVariationValue,
  iconImagePosition,
} from './FlexiContainer.type';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StDTPGridContainer, StDTPWrapper } from '../shared/Container/Container.styles';

const FlexiContainer = (props: IFlexiContainerProps): JSX.Element => {
  const isOneColumn =
    (props?.fields?.componentVariations?.value === componentVariationValue?.OneColumn ||
      props?.fields?.componentVariations?.value === '') ??
    false;
  const invertColorIcon =
    props?.fields?.componentVariations?.value === componentVariationValue?.TwoColumn ?? false;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme?.breakpoints?.down('lg'));
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  if (!props?.fields) return <></>;

  const IconWithTitleProps = {
    titleIcon: props?.fields?.titleIcon,
    title: props?.fields?.title,
    iconWithTitleVariant:
      !isOneColumn && (isTablet || isMobile)
        ? iconImagePosition?.ImageLeft
        : props?.fields?.iconWithTitleVariant.value,
    invertColor: invertColorIcon,
    overrideImageProps: true,
  };
  const IconWithDescriptionProps = {
    bulletDescriptionList: props?.fields?.bulletDescriptionList,
    isOneColumn: isOneColumn,
  };
  return (
    <Grid id={'who-can-take-part'}>
      {isOneColumn && (
        <StDTPWrapper>
          <StDTPGridContainer>
            <IconWithTitle {...IconWithTitleProps} />
            <StTypographyTitle data-testid="heading" variant="h6">
              <Text field={props?.fields?.heading} />
            </StTypographyTitle>
            <IconWithDescriptionBlock {...IconWithDescriptionProps} />
            <Grid paddingTop={'1rem'}>
              <StTypographyDescription variant="description">
                <RichText data-testid="description" field={props?.fields?.description} />
              </StTypographyDescription>
            </Grid>
          </StDTPGridContainer>
        </StDTPWrapper>
      )}
      {!isOneColumn && (
        <StTwoColumnContainer container>
          <StLeftColumnContainer container item lg={6}>
            <IconWithTitle {...IconWithTitleProps} />
          </StLeftColumnContainer>
          <StRightColumnContainer item lg={6}>
            <StTypographyTitle data-testid="heading" variant="h6">
              <Text field={props?.fields?.heading} />
            </StTypographyTitle>
            <IconWithDescriptionBlock {...IconWithDescriptionProps} />
            <Grid paddingTop={'1rem'}>
              <StTypographyDescription variant="description">
                <RichText data-testid="description" field={props?.fields?.description} />
              </StTypographyDescription>
            </Grid>
          </StRightColumnContainer>
        </StTwoColumnContainer>
      )}
    </Grid>
  );
};

export default FlexiContainer;
